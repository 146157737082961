<template>
    <layout-default :sections="sections" :activeSection="activeSection">
        <section class="py-1 bg-light" id="contact" data-description="Standort">
            <div class="container">
                <header-with-line
                    header="So erreichst du uns"
                    class="mb-2"
                />
                <div class="col-lg-9 mx-auto text-center">
                  <div class="text-background h3">Alle Kontaktmöglichkeiten auf einen Blick.</div>
                </div>
              <div class="pt-1">
                <p class="fs-4 text-justify">
                  Ob telefonisch, per E-Mail oder WhatsApp – wir freuen uns darauf, von dir zu hören! Unser Team ist für alle Fragen rund um deinen Termin, deine Therapie und weitere Anliegen da. Nutze einfach die Kontaktmöglichkeit, die dir am besten passt, und wir kümmern uns gerne um dein Anliegen.
                </p>
              </div>
                <div class="row align-items-center">
                    <div class="col-lg-7">
                        <b-carousel class="shadow"
                            id="carousel-2"
                            :interval="6000"
                            fade
                        >
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage1"></b-carousel-slide>
                            <b-carousel-slide :img-src="selectedImage2"></b-carousel-slide>
                        </b-carousel>
                    </div>
                    <div class="col-lg-5 my-1">
                        <div class="row">

                            <div class="col-lg-12 text-center" :class="{ 'bg-primary text-white': selectedColumn === 'map' }"  @mouseover="changeImage('map')">
                                <div class="my-5"><i class="fas fa-5x mb-4 fa-map-marked-alt text-tertiary" :class="{ 'text-background': selectedColumn === 'map' }"></i>
                                    <div class="h5 mb-4">
                                      <a :class="{ 'text-white': selectedColumn === 'map' }" href="https://www.google.com/maps/place/Heinrich-Brandt-Straße+25,+91575+Windsbach" target="_blank" rel="noopener noreferrer">
                                            Heinrich-Brandt-Straße 25<br>
                                            91575 Windsbach
                                        </a>
                                    </div>
                                      <div>
                                        <appointment-reservation fontSize="h5" text="Infos zu Ersttermin" link="/kontakt#first-visit" />
                                      </div>
                                </div>
                            </div>

                            <div class="col-lg-12 text-center" :class="{ 'bg-primary text-white': selectedColumn === 'telefon' }" @mouseover="changeImage('telefon')">
                                <div class="my-5"><i class="fas fa-5x mb-4 fa-calendar-days text-tertiary" :class="{ 'text-background': selectedColumn === 'telefon' }"></i>
                                    <div class="fs-5 mb-4">Termin Reservierung<br />für <span class="text-tertiary">Privatpersonen</span></div>
                                    <div>
                                      <appointment-reservation fontSize="h5" />
                                    </div>
                              </div>
                            </div>

                            <div class="col-lg-12 text-center" :class="{ 'bg-primary text-white': selectedColumn === 'email' }" @mouseover="changeImage('email')">
                                <div class="my-5"><i class="fas fa-5x mb-4 fa-hands-heart text-tertiary" :class="{ 'text-background': selectedColumn === 'email' }"></i>
                                    <div class="h5 mb-4">Termin Reservierung für<br />Soziale Einrichtung</div>
                                    <div>
                                      <appointment-reservation fontSize="h5" text="Bewohner anmelden" :link="'/einrichtung-anmeldung'" />
                                    </div>
                                    <!--
                                    <p>
                                        <a :class="{ 'text-white': selectedColumn === 'email' }" v-bind:href="`mailto:${$t('frame.email')}`">{{ $t('frame.email') }}</a>
                                    </p>
                                    -->
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="bg-white py-1" id="first-visit" data-description="Erster Termin">
          <div class="container">
            <div class="container container-header bg-primary tex-white mb-0">
              <div class="text-center text-white p-5 mb-0 h2">Was solltest du vor deinem ersten Termin wissen?</div>
            </div>
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/TF0Ee1qvC08?autoplay=0&disablekb=1&loop=1&mute=0&modestbranding=1&color=white&iv_load_policy=3&playlist=TF0Ee1qvC08"
              >
              </iframe>
            </div>
          </div>
        </section>
        <section class="bg-white services pt-0 pb-3">
          <your-visit />
        </section>
        <section class="bg-white services pt-0 pb-3">
          <div class="container">
            <div class="col-xl-6 col-lg-6 col-md-9 col-12 mx-auto text-center">
              <clip-board />
            </div>
          </div>
          <div class="container">
            <div class="mx-auto text-center">
              <kontakt-faq />
            </div>
            <div class="mx-auto mt-4 text-center">
              <appointment-reservation />
            </div>
          </div>
        </section>

        <section class="bg-light services p-1" id="accessibility" data-description="Barrierefreiheit">
            <your-accessibility />
        </section>

        <section class="bg-white services p-1" id="facility-registration" data-description="SE Anmeldung">
            <facility-registration />
        </section>
    </layout-default>
</template>

<script>
import { collectSections, observeSections } from '../utils/sectionCollector';
import LayoutDefault from '../layouts/LayoutDefault.vue';
import houseOutside from '../assets/img/haus.jpg';
import houseOutside2 from '../assets/img/haus2.jpg';
import carolinTel from '../assets/img/carolin_tel.jpg';
import helenaTel from '../assets/img/heltel.jpg';
import annePC from '../assets/img/anne_pc.jpg';
import stefaniePC from '../assets/img/stefanie_pc.jpg';
import HeaderWithLine from '../components/Ui/HeaderWithLine.vue';
import YourVisit from '../components/Contact/YourVisit.vue';
import YourAccessibility from '../components/Contact/YourAccessibility.vue';
import ClipBoard from '../components/Contact/ClipBoard.vue';
import FacilityRegistration from '../components/Contact/FacilityRegistration.vue';
import AppointmentReservation from '../components/Ui/AppointmentReservation.vue';
import KontaktFaq from '../components/Contact/KontaktFaq.vue';

export default {
    name: 'Kontakt',
    components: {
        LayoutDefault,
        HeaderWithLine,
        YourVisit,
        YourAccessibility,
        FacilityRegistration,
        ClipBoard,
        AppointmentReservation,
        KontaktFaq
    },
    data() {
        return {
            selectedImage1: carolinTel,
            selectedImage2: helenaTel, // Standardbildpfad
            selectedColumn: 'telefon',
            slide: 0,
            sliding: null,
            sections: [],
            activeSection: ''
        };
    },
    methods: {
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = true;
        },
        updateActiveSection(id) {
            this.activeSection = id;
        },
        changeImage(selectedColumn) {
            switch (selectedColumn) {
            case 'map':
                this.selectedImage1 = houseOutside;
                this.selectedImage2 = houseOutside2;
                this.selectedColumn = 'map';
                break;
            case 'telefon':
                this.selectedImage1 = carolinTel;
                this.selectedImage2 = helenaTel;
                this.selectedColumn = 'telefon';
                break;
            case 'email':
                this.selectedImage1 = stefaniePC;
                this.selectedImage2 = annePC;
                this.selectedColumn = 'email';
                break;
            default:
                this.selectedImage1 = carolinTel;
                this.selectedImage2 = helenaTel;
                this.selectedColumn = 'telefon';
                break;
            }
        }
    },
    mounted() {
        this.sections = collectSections();
        observeSections(this.sections, this.updateActiveSection);
    }
};
</script>

<style scoped>
.container-header {
  border: 2px solid var(--bs-tertiary);
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 0px;
    height: 0;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.header-box {
    border: 5px solid var(--bs-tertiary);
}
</style>
