<template>
    <div>
        <div class="mb-5">
            <header-with-line header="Einrichtungs-Anmeldung" class="mb-4" />
            <div class="col-lg-9 mx-auto text-center">
                <h3 class="text-background">Bewohner einfach und schnell zur Therapie anmelden!</h3>
            </div>
        </div>
        <div class="container fs-4 text-center">
            <b-card no-body class="overflow-hidden bg-transparent">
                <b-row class="d-flex justify-content-center align-items-center">
                    <b-col lg="4">
                        <img src="../../assets/img/facility.png" alt="lift" class="img-fluid img-card" />
                    </b-col>
                    <b-col lg="8">
                        <b-card-body>
                            <b-card-text class="text-justify">
                                <div>
                                    <div class="text-center mb-4">
                                        <img src="../../assets/img/decorations/for_smooth_initial_reception.svg"
                                            class="svg-title" />
                                    </div>
                                    <p>
                                        Mit unserem Anmeldeformular kannst du Bewohner deiner Einrichtung
                                        unkompliziert zur Therapie bei uns anmelden. So haben wir alle wichtigen
                                        Infos direkt vorliegen und können eine passende Versorgung sicherstellen.
                                        Für Folgetermine brauchst du das Formular nicht erneut auszufüllen – wir sorgen
                                        dafür,
                                        dass alles nahtlos weiterläuft!
                                    </p>
                                </div>
                            </b-card-text>
                            <appointment-reservation :text="'Zum Anmeldeformular'" :link="'/einrichtung-anmeldung'" />
                        </b-card-body>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>
<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';
import AppointmentReservation from '../Ui/AppointmentReservation.vue';

export default {
    name: 'YourAccessibility',
    components: {
        HeaderWithLine,
        AppointmentReservation
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.img-card {
    border: 3px solid var(--bs-tertiary) !important;
    border-radius: 10px;
    max-height: 300px !important;
}

.svg-title {
    max-width: 360px;
}

.card {
    border: 0;
}

.title-text {
    border: 3px solid var(--bs-tertiary);
    border-radius: 10px;
}
</style>
