<template>
  <router-link :to="link" exact v-slot="{ navigate, isActive }" custom>
    <div @click="navigate" :class="['custom-button', fontSize, { active: isActive }]">
      {{ text }}
    </div>
  </router-link>
</template>

<script>
export default {
    name: 'AppointmentReservation',
    data() {
        return {
        };
    },
    props: {
        fontSize: {
            type: String,
            default: 'h3'
        },
        text: {
            type: String,
            default: 'zur Terminreservierung'
        },
        link: {
            type: String,
            default: '/appointments#appointment-reservation'
        }
    },
    methods: {
    },
    watch: {
    }
};

</script>
<style>
.custom-button {
  background-color: var(--bs-background);
  color: var(--bs-text);
  border-radius: 50px;
  padding: 20px 30px;
  border: 2px solid var(--bs-text);
  display: inline-block;
  white-space: nowrap;
}

.custom-button:hover {
  background-color: white;
  color: var(--bs-tertiary);
  cursor: pointer;
  transition: 0.3s;
}
</style>
