<template>
  <div class="container">
    <header-with-line header="Häufig gestellte Fragen" class="mb-4" />
    <div class="accordion px-1" role="tablist">
      <b-card no-body v-for="(question, index) in questions" :key="index">
        <b-card-header header-tag="header" role="tab">
          <b-button block v-b-toggle="'accordion-' + index" class="d-flex align-items-center justify-content-left">
            <i :class="accordionOpen[index] ? 'far fa-minus-circle mr-3 text-size' : 'far fa-plus-circle mr-3 text-size'"></i>
            <div class="fs-4 text-left">{{ question.title }}</div>
          </b-button>
        </b-card-header>
        <b-collapse
          :id="'accordion-' + index"
          accordion="my-accordion"
          role="tabpanel"
          v-model="accordionOpen[index]"
        >
          <b-card-body>
            <b-card-text class="text-left fs-5">
              <div class="text-justify" v-html="question.answer"></div>
            </b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
    BCard, BCardBody, BCardHeader, BCardText, BButton, BCollapse
} from 'bootstrap-vue';
import HeaderWithLine from '../Ui/HeaderWithLine.vue';

export default {
    name: 'AnswersQuestions',
    components: {
        HeaderWithLine,
        BCard,
        BCardBody,
        BCardHeader,
        BCardText,
        BButton,
        BCollapse
    },
    data() {
        return {
            questions: [
                {
                    title: 'Habe ich immer den gleichen Therapeuten?',
                    answer: `In der Regel wirst du während der gesamten Behandlungszeit von derselben Therapeut*in betreut
                            – das fördert die besten Therapieergebnisse. Nur bei Urlaub, Krankheit oder geänderten Termin-
                            und Behandlungsanforderungen kann es zu einem Wechsel kommen. Wenn du einen Wechsel wünschst,
                            sei es aus persönlichen Gründen oder anderen Anliegen, sprich uns gerne an – wir sind flexibel und
                            berücksichtigen deine Wünsche bestmöglich.`
                },
                {
                    title: 'Warum untersucht mich der Therapeut, obwohl der Arzt doch schon eine Verordnung ausgestellt hat?',
                    answer:
                          `
                          <p>
                            Die ärztliche Verordnung gibt uns in der Regel nur den betroffenen Bereich an und ist oft in
                            der medizinischen Fachsprache formuliert. Die genaue Ursache für deine Beschwerden ist jedoch nicht immer
                            sofort klar – vor allem, wenn kein Unfall oder keine Operation vorausging. Unsere Therapeut*innen in der Physiotherapie,
                            Ergotherapie und Logopädie nutzen daher das erste Gespräch und gezielte Tests, um ein umfassendes Bild zu gewinnen und
                            die genaue Ursache zu ermitteln.
                          </p>
                          <p>
                            Während der sogenannten Befundung führen wir verschiedene Tests durch, um Hypothesen zu entwickeln
                            und die Problemursache genauer einzugrenzen. Dieser Prozess setzt sich oft während der gesamten
                            Therapie fort: Wir beobachten Fortschritte und passen die Behandlung kontinuierlich an.
                          </p>
                          <p>
                            Ein strukturierter Behandlungsplan hilft uns dabei, die Therapie effektiv und zielgerichtet zu gestalten,
                            da wir auf die neuesten wissenschaftlichen Erkenntnisse zurückgreifen. So arbeiten wir evidenzbasiert
                            und nutzen Methoden, die nachweislich den besten Therapieerfolg bieten.
                          </p>
                          <p>
                            Die Dokumentation jedes Termins dient dazu, deinen Fortschritt festzuhalten, unseren Austausch im
                            Team und mit den Ärzten zu ermöglichen und die Erfolge deiner Behandlung zu sichern.
                          </p>
                          `
                },
                {
                    title: 'Was ist eine Zuzahlung und warum muss ich sie leisten?',
                    answer: `
                          <p>
                            Eine Zuzahlung ist ein Betrag, den gesetzlich versicherte Patient*innen bei einer Heilmittelverordnung selbst zahlen müssen. Sie hilft, die Kosten im Gesundheitssystem zu teilen und trägt dazu bei, die Qualität der Therapie zu sichern. Die genaue Höhe der Zuzahlung hängt von der Behandlung und individuellen Umständen ab – und in einigen Fällen sind sogar Befreiungen möglich, z.B. bei chronischen Erkrankungen, geringem Einkommen oder für Kinder und Jugendliche. Weitere Infos erhältst du bei deiner Krankenkasse.
                          </p>
                          <p>
                            Übrigens: Bei uns kannst du die Zuzahlung auch bequem per EC-Karte bezahlen.
                          </p>
                          `
                },
                {
                    title: 'Warum muss ich ein eigenes Handtuch zur Therapie mitbringen?',
                    answer: `
                          <p>
                            Aus Hygienegründen bitten wir alle Patient*innen, ein eigenes Handtuch zur Behandlung mitzubringen.
                            So können wir sicherstellen, dass die höchsten Hygienevorgaben eingehalten werden und jede Behandlung
                            auf einer frischen, sauberen Unterlage stattfindet. Die Lagerung und Reinigung von Handtüchern ist uns
                            seit den erhöhten Hygienestandards durch Corona vom Gesundheitsamt nicht mehr erlaubt.
                            Ein eigenes Handtuch zu verwenden, ist daher die beste Lösung für deine Sicherheit und unseren Praxisalltag.
                          </p>
                          <p>
                            Falls du dein Handtuch mal vergessen solltest, stellen wir dir gerne ein Ersatzhandtuch zur Verfügung.
                            Dafür erheben wir eine kleine Gebühr, um die Wasch- und Aufbereitungskosten zu decken.
                          </p>
                          `
                },
                {
                    title: 'Was muss ich tun, wenn ich meinen Termin absagen muss?',
                    answer: `
                          <p>
                            Wenn du deinen Termin nicht wahrnehmen kannst, informiere uns bitte so früh wie möglich – idealerweise mindestens
                            24 Stunden vorher. So können wir den Termin anderweitig vergeben und dir eine Ausfallsrechnung ersparen.
                            Du kannst uns dafür einfach anrufen, eine E-Mail schreiben oder über WhatsApp benachrichtigen.
                          </p>
                          <p>
                            Falls du uns jedoch nicht rechtzeitig Bescheid gibst und der Termin nicht wahrgenommen wird,
                            müssen wir leider eine Ausfallsrechnung stellen. Wir wissen, dass unerwartete Dinge passieren können,
                            und danken dir für deine Zusammenarbeit und dein Verständnis!
                          </p>
                            `
                },
                {
                    title: 'Wann muss eine Ausfallgebühr bezahlt werden und warum unterscheiden sich die Gebühren?',
                    answer: `
                          <p>
                            Eine Ausfallgebühr wird fällig, wenn du deinen reservierten Termin nicht mindestens 24 Stunden im Voraus absagst.
                            Die Höhe der Gebühr hängt von der Art der Behandlung ab, die wir für dich reserviert hatten, da Krankengymnastik,
                            manuelle Therapie und andere Leistungen unterschiedlich abgerechnet werden. Wir berechnen die Gebühr,
                            um den Verdienstausfall zu decken, da wir in dieser Zeit keine anderen Patient*innen behandeln können.
                          </p>
                          `
                },
                {
                    title: 'Warum kann ich den Termin nicht einfach auf meiner Verordnung als „erledigt“ unterschreiben, wenn ich nicht da war?',
                    answer: 'Nur tatsächlich durchgeführte Leistungen dürfen auf der Verordnung quittiert werden – eine Unterschrift für einen nicht wahrgenommenen Termin wäre Betrug. Bei gesetzlich Versicherten erlaubt das Gesetz keine Vordatierungen oder „Globalunterschriften“, und auch bei Privatpatienten muss eine Ausfallgebühr separat als solche deklariert werden, um Missverständnisse zu vermeiden.'
                },
                {
                    title: 'Warum kann es trotz Termin zu Verzögerungen kommen?',
                    answer: `
                            <p>
                              In unserer Praxis arbeiten wir nach dem Bestellprinzip, was bedeutet, dass wir für jeden Patientin eine
                              feste Zeit reservieren. Dennoch kann es gelegentlich zu Verzögerungen kommen. Manchmal erfordert eine Behandlung
                              etwas mehr Zeit oder es treten unvorhergesehene Ereignisse auf, die den Zeitplan beeinflussen.
                            </p>
                            <p>
                              Wir achten darauf, dass jeder Patientin die notwendige Zeit und Aufmerksamkeit bekommt,
                              um eine qualitativ hochwertige Therapie zu gewährleisten – dies gilt natürlich auch für dich.
                              Um solche Verzögerungen aufzufangen, planen wir im Tagesablauf kleine Pufferzeiten ein
                              sodass sich die Wartezeit meist auf ein Minimum beschränkt.
                            </p>
                            `
                }
            ],
            accordionOpen: []
        };
    },
    methods: {
    },
    mounted() {
        this.accordionOpen = this.questions.map(() => false);
    }
};
</script>

<style>
.text-size {
    font-size: 2em;
    color: var(--bs-text);
}
</style>
