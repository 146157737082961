<template>
  <div>
    <div class="mb-5">
      <header-with-line header="Barrierefreiheit" class="mb-4"/>
      <div class="col-lg-9 mx-auto text-center">
          <h3 class="text-background">… für uns eine Selbstverständlichkeit!</h3>
      </div>
    </div>
    <div class="container fs-4 text-center">
      <b-card no-body class="overflow-hidden bg-transparent" >
        <b-row class="d-flex justify-content-center align-items-center">
          <b-col lg="8">
            <b-card-body>
              <b-card-text class="text-justify">
                <p>
                  Unser Therapiezentrum ist komplett barrierefrei.
                  Ein moderner Aufzug sorgt dafür, dass Rollstuhlfahrer*innen und
                  Personen mit eingeschränkter Mobilität bequem zu allen Bereichen gelangen.
                  Wir kümmern uns darum, dass dein Besuch bei uns einfach und angenehm verläuft, damit du dich voll und ganz auf deine Therapie konzentrieren kannst.
                </p>
              </b-card-text>
              <b-card-text class="bg-primary text-white p-3 title-text">
                Weiter kommen ist mehr als <span class="text-tertiary">Parkplätze</span> und <span class="text-tertiary">Berrierefreiheit</span>
              </b-card-text>
              <div class="mt-5">
                <appointment-reservation />
              </div>
            </b-card-body>
          </b-col>
          <b-col lg="4">
            <img src="../../assets/img/lift.png" alt="lift" class="img-fluid img-card"/>
          </b-col>
        </b-row>
      </b-card>
    </div>
    </div>
</template>
<script>
import HeaderWithLine from '../Ui/HeaderWithLine.vue';
import AppointmentReservation from '../Ui/AppointmentReservation.vue';

export default {
    name: 'YourAccessibility',
    components: {
        HeaderWithLine,
        AppointmentReservation
    },
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
    watch: {
    }
};
</script>
<style>
.img-card {
  border: 3px solid var(--bs-tertiary) !important;
  border-radius: 10px;
  max-height: 300px !important;
}

.card {
  border: 0;
}

.title-text {
  border: 3px solid var(--bs-tertiary);
  border-radius: 10px;
}
</style>
